import React from 'react';

import LinkItem from '../../components/LinkItem';

const Payments = () => (
  <LinkItem
    href="/profile#/payments"
    id="payments-button"
    title="Payments"
  />
);

export default Payments;
