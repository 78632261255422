import React from 'react';

import LinkItem from '../../components/LinkItem';

const Membership = () => (
  <LinkItem
    href="/profile#/membership"
    id="membership-button"
    title="Membership"
  />
);

export default Membership;
