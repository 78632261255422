import React, { useState } from 'react';

const ShowMore = ({ items, maxVisible }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const shouldTruncate = items.length > maxVisible;
  const fullVisibleItems = maxVisible - 2;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <ul
      className={`choose-plan__item-list check-mark-style${
        !isExpanded && shouldTruncate ? ' has-opacity' : ''
      }`}
    >
      {!isExpanded && (
        <>
          {items.slice(0, fullVisibleItems).map((item) => (
            <li key={item.name}>{item.name}</li>
          ))}

          {shouldTruncate && (
            <li className="no-mark">
              <button className="plus" onClick={toggleExpand}>
                +
              </button>
            </li>
          )}

          {items.slice(fullVisibleItems, fullVisibleItems + 2).map((item) => (
            <li key={item.name}>{item.name}</li>
          ))}
        </>
      )}

      {isExpanded && (
        <>
          {items.map((item) => (
            <li key={item.name}>{item.name}</li>
          ))}

          {shouldTruncate && (
            <li className="no-mark">
              <button className="minus" onClick={toggleExpand}>
                -
              </button>
            </li>
          )}
        </>
      )}
    </ul>
  );
};

export default ShowMore;
