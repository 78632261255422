import React from 'react';

import ButtonItem from '../../components/ButtonItem';

let SCRF_TOKEN = null;
const SCRF_TOKEN_ELEMENTS = document.getElementsByName('csrf-token');

if (SCRF_TOKEN_ELEMENTS.length) {
  SCRF_TOKEN = SCRF_TOKEN_ELEMENTS[0].content;
}

const LogOut = () => (
  <form method="POST" action="/logout">
    <input type="hidden" name="_token" value={SCRF_TOKEN} />

    <ButtonItem
      href="/logout"
      id="logout-button"
      title="Log Out"
      className="navlist__btn navlist__item logout"
      type="submit"
    />
  </form>
);

export default LogOut;
