import apiClient from '@jsv3/services/apiClient';

/**
 * Get a list of user payment methods.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getPaymentMethodListAPI = () => apiClient.get('user/payment-methods');

/**
 * Get a list of user social providers.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getSocialProviderListAPI = () => apiClient.get('user/social-providers');

/**
 * Get a user subscription.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getSubscriptionAPI = () => apiClient.get('user/subscription');

/**
 * Delete user.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteUserAPI = () => apiClient.delete('user');

/**
 * Change default payment  method.
 *
 * @param {object} postData
 */
export const changeDefaultPaymentMethodAPI = (postData) =>
  apiClient.post('user/membership', postData);

/**
 * Disconnect a social account.
 *
 * @param {object} postData
 */
export const disconnectSocialAccountAPI = (postData) =>
  apiClient.put('user/disconnect-social-account', postData);

/**
 * Disconnect user social providers.
 */
export const getUserSocialProvidersAPI = () => apiClient.get('user/social-providers');

/**
 * @param paymentId
 * @return {*}
 */
export const deletePaymentMethodAPI = (paymentId) =>
  apiClient.put('user/forget-saved-payment-method', { payment_method_id: paymentId });

/**
 * @param paymentData
 * @return {*}
 */
export const addPaymentMethodAPI = (paymentData) => apiClient.post('user/attach-cc', paymentData);

/**
 * @param paymentMethodId
 * @param paymentData
 * @return {*}
 */
export const editPaymentMethodAPI = (paymentMethodId, paymentData) =>
  apiClient.put(`user/edit-cc/${paymentMethodId}`, paymentData);

/**
 * @param data
 * @return {*}
 */
export const changeSubscriptionsAPI = (data) => apiClient.post('user/change-subscriptions', data);

/**
 * @return {*}
 */
export const getUserSubscriptionAPI = () => apiClient.get('user/subscription');
