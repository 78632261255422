import React, { useContext } from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Price from '@components/Price';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import { getBlackIconUrl } from '@utils/icons';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import SignUpContext from '@jsv2/context/SignUpContext';

const BecomeSection = React.forwardRef(({ id, ctaTitle }, ref) => {
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton, hasPremiumMembership } = useContext(LPContext);
  const { membershipSummary } = useContext(SignUpContext).signUpProcess;
  const {
    recurringInterval,
    moneybackPeriod,
    benefits,
    signupBonus,
    recurringCharge,
  } = membershipSummary;

  const renderMembershipSummary = (hasPremiumPlan) => {
    if (hasPremiumPlan && recurringCharge.current.amount > 0) {
      return (
        <p className="offer-price" data-qa-id="membership-price-value">
          {t('FREE*')}
        </p>
      );
    }

    return recurringCharge.current.amount === 0 ? (
      <p className="offer-price" data-qa-id="membership-price-value">
        {t('FREE')}
      </p>
    ) : (
      <>
        <div className="offer-price-block" data-qa-id="membership-price">
          <Price
            inline
            price={recurringCharge.current.amount}
            currency_iso3={recurringCharge.current.currencyIso3}
            hideDecimalsForIntegers
            dataQaId="membership-price-value"
          />
          {moneybackPeriod.current > 0 && t('*')}
        </div>

        <p className="price__interval" data-qa-id="price_interval">
          {recurringInterval.current}
        </p>
      </>
    );
  };

  /**
   * @param {object} benefit
   * @return {JSX.Element}
   */
  const renderBenefitItem = (benefit) => (
    <li
      key={benefit.id}
      className="list__item"
      style={{
        background: `url(${getBlackIconUrl(benefit.icon)}) no-repeat`,
      }}
    >
      {benefit.name && <strong>{benefit.name}</strong>} {benefit.description}
    </li>
  );

  const renderInclusionsList = () => (
    <ul className="inclusions__list full">
      {benefits.current.map((benefit) => renderBenefitItem(benefit))}

      {signupBonus.current && (
        <li className="list__item card" data-qa-id="membership-credit-issuance">
          Plus{' '}
          <strong>
            <Price
              inline
              price={signupBonus.current.amount}
              currency_iso3={signupBonus.current.currencyIso3}
              hideDecimalsForIntegers
              dataQaId="credit_value"
            />{' '}
            VIP Traveler Dollars
          </strong>{' '}
          to get you started
        </li>
      )}
    </ul>
  );

  const renderInclusionsNote = (hasPremiumPlan) => {
    if (hasPremiumPlan) {
      return (
        <p className="inclusions__note" data-qa-id="inclusions_note">
          {t('*Premium membership available at an additional cost')}
        </p>
      );
    }

    if (moneybackPeriod.current > 0) {
      return (
        <p className="inclusions__note" data-qa-id="inclusions_note">
          {t(
            '*Our Happiness Guarantee: If for any reason you’re not loving your membership, let us know within ',
          )}
          <span data-qa-id="moneyback">{pluralize(t('day'), moneybackPeriod.current, true)}</span>
          {t(' and we’ll refund you in full, no questions asked.')}
        </p>
      );
    }

    return null;
  };

  return (
    editableFields && (
      <section className="become-section" ref={ref} id={id} data-qa-id="become-section">
        <div className="become-section__border">
          <div className="become-section__wrapper">
            <h2 className="section-title section-title--center">
              <Editable fieldValue={editableFields.vip_heading} />
            </h2>

            <div className="section-description section-description--center">
              <Editable fieldValue={editableFields.vip_testimonial} />
            </div>

            {renderInclusionsList()}

            <div className="price">{renderMembershipSummary(hasPremiumMembership)}</div>

            {renderBecomeButton('become-btn become-btn--red', ctaTitle, 'become-join-btn')}

            {renderInclusionsNote(hasPremiumMembership)}
          </div>
        </div>

        {editableFields.choose_plan_footer_note && (
          <div className="main-container">
            <div className="section-testimonial mt-30 mb-0">
              <Editable fieldValue={editableFields.choose_plan_footer_note} />
            </div>
          </div>
        )}
      </section>
    )
  );
});

BecomeSection.propTypes = {
  ctaTitle: PropTypes.string,
  id: PropTypes.string.isRequired,
};

BecomeSection.defaultProps = {
  ctaTitle: 'join us',
};

BecomeSection.displayName = 'BecomeSection';

export default ErrorBoundaryDecorator()(BecomeSection);
