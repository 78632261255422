import React from 'react';

import LinkItem from '../../components/LinkItem';

const PaymentMethods = () => (
  <LinkItem
    href="/profile#/payment-methods"
    id="payment-methods-button"
    title="Payment Methods"
  />
);

export default PaymentMethods;
