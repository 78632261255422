import React from 'react';

import { isAnonymous } from '@jsv2/utils/UserUtils/userStatuses';
import { getLinkToGo, getBackLinkToGo } from '@jsv3/utils/urlUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const {
  userRoles,
  __SERVER_DATA__: {
    offer,
    shared_data: { defaultHomePageUrl },
  },
} = window;

const Logo = () => {
  const createLogoLink = () => {
    if (isAnonymous(userRoles)) {
      if (offer) {
        return `${getLinkToGo()}&go_back_url=${getBackLinkToGo()}`;
      }

      return getLinkToGo();
    }

    return defaultHomePageUrl;
  };

  return (
    <a href={createLogoLink()} id="logo-link" className="main-header__logotype">
      <img src="/images/lp/promo2/logotype-black.svg" alt="Vip Traveler" />
    </a>
  );
};

export default ErrorBoundaryDecorator()(Logo);
