import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, {
  Editable,
  EditableImg,
} from '@jsv2/LandingPage/context/EditFieldContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import LeftContentInSection from '../components/LeftContentInSection';

const ContentWithBenefitsSection = ({ id, className }) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);

  const benefits = [
    {
      id: 1,
      title: editableFields.redesign_section_3_benefit_1_title,
      content: editableFields.redesign_section_3_benefit_1_text,
      image: editableImageFields.redesign_section_3_benefit_1_image,
    },
    {
      id: 2,
      title: editableFields.redesign_section_3_benefit_2_title,
      content: editableFields.redesign_section_3_benefit_2_text,
      image: editableImageFields.redesign_section_3_benefit_2_image,
    },
    {
      id: 3,
      title: editableFields.redesign_section_3_benefit_3_title,
      content: editableFields.redesign_section_3_benefit_3_text,
      image: editableImageFields.redesign_section_3_benefit_3_image,
    },
  ];

  return (
    <LeftContentInSection
      id={id}
      childrenClasses={className}
      heading={editableFields.redesign_section_3_benefit_heading}
      testimonial={editableFields.redesign_section_3_benefit_testimonial}
    >
      <div className="right-benefits-list">
        {benefits.map((benefit, index) => (
          <div key={benefit.id} className="right-benefits-list__item">
            <div className="right-benefits-list__item-icon">
              {benefit.image && (
                <EditableImg image={benefit.image} width={70} height={70}>
                  <Image
                    data={benefit.image && benefit.image.content}
                    type="PageContent"
                    config={{
                      size: IMAGE_SIZES.IMAGE_W350,
                    }}
                    background
                  />
                </EditableImg>
              )}
            </div>

            <div className="right-benefits-list__item-content">
              <div className="right-benefits-list__item-title">
                <Editable fieldValue={benefit.title} />
              </div>

              <div className="common-text">
                <Editable fieldValue={benefit.content} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </LeftContentInSection>
  );
};

ContentWithBenefitsSection.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ContentWithBenefitsSection.defaultProps = {
  className: '',
};

export default ErrorBoundaryDecorator()(ContentWithBenefitsSection);
