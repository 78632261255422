import React from 'react';

import LinkItem from '../../components/LinkItem';

const Settings = () => (
  <LinkItem
    href="/profile#/account-settings"
    id="account-setting-button"
    title="Settings"
  />
);

export default Settings;
