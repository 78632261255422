import React from 'react';

import LinkItem from '../../components/LinkItem';

const UpgradePlan = () => (
  <LinkItem
    href="/vip-ykaxcbtz"
    id="upgrade-to-vip-plus"
    title="Upgrade to VIP Plus"
    className="upgrade-link"
  />
);

export default UpgradePlan;
