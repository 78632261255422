import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { setActiveMenuItemAction } from '@js/ducks/menuItems';

const linksData = {
  'vip exclusives': '/home',
  'top picks': '/home',
  'my travel finds': '/travel-finds',
  'my stylist': '/stylist',
  // 'luxury listings': '/hotel-search',
  'my bucket list': '/bucket-list',
  settings: '/profile#/account-settings',
  'my trips': '/profile#/my-trips',
  'email frequency': '/profile#/email-preferences',
  'credit balance': '/profile#/balance',
  payments: '/profile#/payments',
  membership: '/profile#/membership',
  'payment methods': '/profile#/payment-methods',
};

const LinkItem = ({ id, title, href, className, activeMenuItem, setActiveMenuItem }) => {
  const currentItem = linksData[title.toLowerCase()];

  return (
    <a
      className={classNames('navlist__btn', className, {
        active: activeMenuItem === currentItem,
      })}
      href={href}
      data-qa-id={id}
      key={id}
      onClick={() => setActiveMenuItem(currentItem)}
    >
      {title}
    </a>
  );
};

LinkItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkItem.defaultProps = {
  className: '',
};

const mapStateToProps = ({ menuItems: { activeMenuItem } }) => ({
  activeMenuItem,
});

const mapDispatchToProps = {
  setActiveMenuItem: setActiveMenuItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkItem);
