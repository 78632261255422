import React from 'react';

import LinkItem from '../../components/LinkItem';

const Trips = () => (
  <LinkItem
    href="/profile#/my-trips"
    id="account-orders-button"
    title="My Trips"
  />
);

export default Trips;
