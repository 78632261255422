import React from 'react';

import LinkItem from '../../components/LinkItem';

const EmailFrequency = () => (
  <LinkItem
    href="/profile#/email-preferences"
    id="email-preferences-button"
    title="Email Frequency"
  />
);

export default EmailFrequency;
