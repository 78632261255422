import React from 'react';

import LinkItem from '../../components/LinkItem';

const Balance = () => (
  <LinkItem
    href="/profile#/balance"
    id="balance-button"
    title="Credit Balance"
  />
);

export default Balance;
